const INFO = {
	main: {
		title: "Portfolio | Arturo García Rodríguez",
		name: "Arturo García Rodríguez",
		email: "arturotphx@gmail.com",
		logo: "../foto_informal.jpg",
	},

	socials: {
		github: "https://github.com/ArturoTphx/",
		linkedin: "https://www.linkedin.com/in/arturotphx",
	},

	homepage: {
		title: "Junior Data Analyst",
		description:
			"My current strength lies in Data Analysis. I am passionate about learning new things and improve my way to accomplish tasks in different ways. Working in team is always an enjoyable experience for me, and I am open to new challenges that involves the thinking of many people working together.",
	},

	about: {
		title: "I’m Arturo García Rodríguez. I live in Costa Rica, where I design innovative ideas.",
		description:
			"I have worked on a variety of projects; some in the private sector and others in the public sector. I love communicating with my clients and listening to their needs in order to plan and execute the features they want. Because of that, I accomplish many projects such as an ERP for a local government  or a complete system for a private start-up.",
	},

	projects: [
		{
			title: "Taco Bell BI",
			description:
				"Business Intelligence system for Taco Bell Costa Rica. Machine Learning models, AI and Data Analysis made with Python.",
			logo: "./cabysis.png",
			linkText: "View Project",
			link: "",
		},

		{
			title: "Note Me",
			description:
				"Native Android App built with Jetpack Compose, MVVM and Dagger Hilt for educational purposes.",
			logo: "./genius.png",
			linkText: "View Project",
			link: "https://docs.google.com/document/d/1akBkOhpit-y_LUM7QfDYIKKfMQfaz0qpVNNQbKle-zs/edit?usp=sharing",
		},

		{
			title: "Módulo de Definición y Seguimiento de los Planes Anuales Operativos",
			description:
				"Construction of a web application using PHP Laravel framework in which users can plan their projects",
			logo: "./mora.jpg",
			linkText: "View Project",
			link: "https://docs.google.com/document/d/1UYsBNQYaiKPCQK1hkYBfUunFCoCQJPWR0BfFFM92G_c/edit?usp=drive_link",
		},

		{
			title: "Logic Expressions Analyzer",
			description:
				"Java Desktop App that analyzes a logic expression and generates a truth table, disjunctive, conjunctive and simplified form",
			logo: "./una.png",
			linkText: "View Project",
			link: "https://docs.google.com/document/d/1VayWHT8sZXhH3AZsWPAqPpO3zMTdj0trgIhHWrckuas/edit?usp=sharing",
		},

		{
			title: "Creation of mobile applications",
			description:
				"Native mobile apps made with: Kotlin, Jetpack Compose, Swift and SwiftUI who consumes a REST API",
			logo: "./genius.png",
			linkText: "View Project",
			link: "https://docs.google.com/document/d/1QfFe3Rhyz-MgrhS1XrzyCyKPXQWCWIHCEWjLnbhWQEQ/edit?usp=sharing",
		},

		{
			title: "Creation of technological foundations",
			description:
				"Design and creation of MySQL database, REST API, React JS web apps, and company emails",
			logo: "./genius.png",
			linkText: "View Project",
			link: "https://docs.google.com/document/d/1EWF7jj__LFXuI0y91CXgSNiJGtjKGJxTshP_aHSIK4Q/edit?usp=sharing",
		},
	],
};

export default INFO;
